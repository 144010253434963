.footer {
  width: 100%;
  position: relative;

  section {
    @include module;
    max-width: 91%;
    width: 100%;
    @include deskOffice{
      max-width: 75% !important;
    }
    @include phone{
      max-width: 100%;
      margin: 0;
    }
  }

  &--breadcrumb {
    width: 100%;
    background-color: #cacaca;
    nav {
      width: 100%;
      @include padbox(0.5em);
      @include module;
      @include desk {
        padding: 0.5em 0;
      }
      a {
        color: $grey;
        padding-right: 6px;
        font-size: 0.9em;
        &::after {
          content: "/";
          display: inline-block;
          padding-left: 6px;
        }
        &:last-of-type {
          color: $black;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &--links {
    width: 100%;
    background-color: $darkgray;
    color: $light;
    @include padblock(1em);
    height: auto;
    @include phone{
      padding: 0;
    }
    &-cont{
      display: flex;
      // gap: 35
      flex-wrap: wrap;
      width: 100%;
      &-mobile{
        display: none;
        @include phone{
          display: block;
          width: 100%;
          .footer-links-element{
            width: 100%;
            border-bottom: 1px solid #6c6c6c;
            &.active{
              .footer-links-element-tittle{
                i{
                  transform: rotate(180deg);
                }
              }
            }
            &-tittle{
              justify-content: space-between;
              padding: 20px 17px 20px 20px;
              display: flex;
              i{
                font-size: 1.5rem;
                color: #BBB;
                transition: all 0.3s ease;
              }
              a{
                color: #fff;
                font-weight: 700;
                font-size: 16px;
              }
            }
            &-list{
              color: #fff;
              display: none;
              padding-bottom: 10px;
              &.active{
                display: block;
                li{
                  padding: 12px 20px;
                  font-size: .875rem;
                  a{
                    color: #DBDBDB;
                    font-weight: 400;
                    padding: 6px 0;
                  }
                }
              }
            }
          }
        }
      }
      @include tablet{
        display: flex;
      }
      @include phone{
        display: none;
      }
      ul{
        width: 20% !important;
        li{
          width: 100%;
          white-space: nowrap;
          b{
            font-size: 16px !important
          }
        }
      }

    }
    section {
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-start;
    }
    ul {
      display: inline-block;
      width: 50%;
      @include tablet {
        width: 25%;
      }
      @include desk {
      }
      li {
        width: 100%;
        text-align: left;
        padding: 16px 0 0;
        line-height: 1.4;
        a {
          font-size: .875rem;
          @include padbox(0.35em 0em);
          color: $light;
          font-weight: 400;
        }
        b {
          font-size: 0.9em;
          @include padbox(0.5em 0em);
        }
      }
      // &:last-of-type {
      //   li:last-of-type {
      //     a {font-weight: 600; font-size: 0.9em; color: $dark; margin-top: 2em;}
      //   }
      // }
    }
  }

  &--social {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    @include phone{
      display: flex;
      flex-direction: column;
      padding: 16px 20px 32px;
      &-tittle{
        display: block !important;
        color: #F65041;
        font-weight: 400;
      }
      &-icons{
        margin-top: 14px;
      }
    }
    &-icons{
      display: flex;
    }
    &-tittle{
      display: none;
    }
    p {
      width: 100%;
      font-size: 0.9em;
      font-weight: 500;
      margin-bottom: 0.5em;
    }
    a {
      font-family: $icon;
      margin-right: 0.5em;
      background-color: $light;
      font-size: 2em;
      width: 32px;
      display: flex;
      height: 32px;
      border-radius: 50%;
      span{
        font-size: 1.125rem;
        margin: 7px;
        color: #3C3C3C;
      }
    }
  }

  &--endC {
    width: 100%;
    background-color: $red;
    @include padbox(1em 0);
    position: relative;
    section {
      @include flexbox;
      @include flow-rw;
      @include just-between;
      @include items-center;
    }
    p {
      @include padblock(0em 1em);
      width: auto;
      @include tablet {
        padding: 0.5em;
      }
      @include desk {
        padding: 0.5em 0;
      }
      span {
        display: inline-block;
        color: white;
        font-size: 0.8em;
        width: 100%;
        @include tablet {
          width: auto;
        }
        svg {
          height: 21px;
          width: auto;
          margin-right: 9px;
        }
      }
    }
    nav {
      @include padblock(0.5em 1em);
      width: 100%;
      @include tablet {
        width: auto;
        padding: 0 0.5em;
      }
      @include desk {
        padding: 0.5em 0.5em 0 0.5em;
      }
      a {
        color: $blue;
        @include padbox(0.5em);
        font-size: 0.8em;
        width: 50%;
        @include tablet {
          width: auto;
        }
      }
    }
    picture {
      z-index: 1999999;
      background-color: transparent;
      border: none;
      width: 190px;
      height: auto;
      position: fixed;
      right: -95px;
      background-size: contain;
      transition: background 0.4s, right 1s;
      bottom: 15%;
      box-sizing: border-box;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        right: -5px;
      }
    }
  }

  &--end {
    width: 100%;
    background-color: $mediumgray;
    padding: 10px 0 0;
    position: relative;
    @include phone {
      section{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        nav{
          display: flex !important;
          flex-wrap: wrap;
          justify-content: center;
          a{
            width: auto;
          }
        }
      }
    }
    section {
      @include flexbox;
      @include flow-rw;
      @include just-between;
      align-items: baseline
    }
    p {
      @include padblock(0em 1em);
      width: auto;
      @include tablet {
        padding: 0.5em;
      }
      @include desk {
        padding: 0.5em 0;
      }
      span {
        display: inline-block;
        color: white;
        font-size: .875rem;
        width: 100%;
        @include tablet {
          width: auto;
        }
        svg {
          height: 21px;
          width: auto;
          margin-right: 9px;
        }
      }
    }
    nav {
      @include padblock(0.5em 1em);
      width: 100%;
      @include tablet {
        width: auto;
        padding: 0 0.5em;
      }
      @include desk {
        padding: 0.5em 0.5em 0 0.5em;
      }
      #separator0{
        display: none;
      }
      .separatorLinks{
        color: $light;
      }
      a {
        color: $light;
        @include padbox(0.5em);
        font-size: .875rem;
        padding: 6px;
        text-decoration: underline;
        width: 50%;
        @include tablet {
          width: auto;
        }
      }
    }
    picture {
      z-index: 1059;
      background-color: transparent;
      border: none;
      width: 190px;
      height: auto;
      position: fixed;
      right: -95px;
      background-size: contain;
      transition: background 0.4s, right 1s;
      bottom: 18%;
      box-sizing: border-box;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        right: -5px;
      }
    }
  }

  &--country {
    position: absolute;
    top: 0.5em;
    right: 1em;
    width: 7.5em;
    @include tablet {
      display: none;
    }
    label {
      position: relative;
      width: 100%;
      select {
        color: whitesmoke;
        font-size: 0.8em;
        font-weight: 300;
        z-index: 1;
        width: 9em;
      }
    }
    span {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      z-index: 0;
      svg {
        height: 1.3em;
        width: auto;
        display: block;
      }
      &::before {
        content: "\f107";
        font-family: $icon;
        padding-left: 3px;
        position: absolute;
        top: 2px;
        left: -18px;
        color: whitesmoke;
      }
    }
  }

  &--chat {
    position: fixed;
    right: -1px;
    bottom: 21%;
    cursor: pointer;
    display: none;
    @include tablet {
      display: inline-block;
      z-index: 2;
    }
    a {
      border: 1px solid $sky;
      text-align: center;
      max-width: 73px;
      opacity: 0.6;
      span {
        display: block;
        @include padbox(0.05em 0.5em);
        svg {
          height: 34px;
          width: auto;
        }
      }
      label {
        background-color: $sky;
        color: $blue;
        @include padblock(0.25em 0.5em);
        font-size: 0.8em;
        @include trans;
        cursor: pointer;
      }
      &:hover {
        opacity: 1;
        border-color: $blue;
        label {
          background-color: $blue;
          color: white;
        }
      }
    }
  }

  &--chatbox-pc {
    display: block;
    @include phone {
      display: none;
    }
  }
  &--chatbox-mobile {
    display: none;
    width: 80px !important;
    right: 10px !important;
    // bottom: 10% !important;
    &:hover {
      right: 15px !important;
    }
    @include phone {
      display: block;
    }
  }
}

.Footer--image {
  padding: 0.5rem 1rem;
  min-height: 9rem;
  background-color: $red;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  align-items: center;
}
.Footer--image-frist {
  position: relative;
}
.comenta {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  top: -30px;
  text-align: right;
  color: #fff;
  border-top: 87px solid transparent;
  border-left: 100px solid $red;
  border-bottom: 0px solid transparent;
  &::after {
    content: "Comenta";
    font-size: 0.8rem;
    left: -80px;
    top: -38px;
    position: absolute;
    transform: rotate(38deg);
    color: #fff;
  }
}
.Footer--container-img {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px 0 0 -8px;
  width: calc(100% + 8px);
}
.Footer--container-img-siteseal {
  display: flex;
  justify-content: center;
}
.Footer--container-img div {
  margin: 8px 0 0 8px;
  border-radius: 12px;
  height: 37px;
  // max-width: 50%;
  max-width: 47%;
  min-width: 46%;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.58);
}
.Footer--image-item .title-footer {
  font-family: $font;
  font-weight: 700;
  margin: 0.4rem 0;
  color: white;
  font-size: 16px;
  text-align: center;
}
.Footer--image-item .subtitle-footer {
  font-family: $font;
  margin-top: 0.2rem;
  color: white;
  font-size: 14px;
  text-align: center;
}
.Footer--container-img div img {
  max-height: 50px;
  object-fit: contain;
  //width: 155px;
}
.Footer--image-item {
  max-width: 100%;
  padding: 0 1rem;
  min-height: 110px;
}
.Footer--image-item:nth-child(2) {
  margin-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-left: none;
  border-right: none;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.Footer--image .siteseal--container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.4rem;
  align-items: center;
}
.Footer--image .siteseal--container span {
  max-height: 45px;
}
.Footer--image .siteseal--container span img {
  height: 45px;
  width: 162px;
}

@media (min-width: 992px) {
  .Footer--container-img div {
    height: 45px;
  }
  .Footer--image {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .Footer--image-item:nth-child(2) {
    margin-top: 0;
    padding-bottom: 0;
    border-top: none;
    border-bottom: none;
    border-left: 4px solid white;
    border-right: 4px solid white;
  }

  .Footer--image-item .title-footer {
    font-size: 16px;
    margin: 0 0 25px 0;
    font-weight: 400;
  }
  .Footer--image-item .subtitle-footer {
    font-size: 14px;
    display: none;
  }
  .Footer--image .siteseal--container {
    display: block;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .Footer--container-img div {
    min-width: 46%;
  }
}
@media (min-width: 1320px) {
  .Footer--container-img div {
    min-width: 47%;
  }
}

@media (max-width: 768px) {
  .Footer--image-frist {
    .comenta {
      top: -87px;
      &::after {
        font-size: 0.7rem;
        left: -86px;
        top: -35px;
      }
    }
    .Footer--image-item {
      padding: 36px 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title-footer {
        margin-bottom: 30px;
        font-weight: 300;
        font-size: 19px;
      }
      .subtitle-footer {
        display: none;
      }
    }
    .Footer--image-item:nth-child(2) {
      border-top: 4px solid white;
      border-bottom: 4px solid white;
    }
  }
  .footer--breadcrumb {
    nav {
      display: flex;
      align-items: center;
      padding: 18px 15px !important;
      p {
        font-size: 14px;
        text-align: left;
        line-height: 2;
      }
    }
  }
}
